<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list
      dense
      nav
    >
      <v-list-item
        @click="$router.push({name: 'Customer Index'})"
      >

        <img
          src="@/assets/ac1.png"
          alt="Logo"
          style="max-width:50px; margin-right: 10px"
        />

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="'Business Manager'"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >

      <div />

      <template v-for="(item, i) in ComputedButtons">
        <v-divider
          v-if="item.separator"
          class="mb-2"
          :key="`divider-${i}`"
        ></v-divider>
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapState,
} from 'vuex';

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-account-plus',
        title: 'New Lead',
        to: '/application/new-lead',
        permissions: ['create-customer'],
      },
      {
        icon: 'mdi-account-multiple',
        title: 'Customers',
        to: '/application/customer-index',
        permissions: [],
      },
      {
        icon: 'mdi-wallet',
        title: 'Wallet',
        to: '/application/transactions',
        permissions: ['get-transactions'],
        separator: true,
      },
      {
        icon: 'mdi-clock-outline',
        title: 'Sessions',
        to: '/application/sessions',
        permissions: [],
        separator: true,
      },
      {
        icon: 'mdi-chart-line',
        title: 'Finances',
        to: '/application/finances',
        permissions: ['<view finances>'],
        separator: true,
      },
      {
        icon: 'mdi-chart-box-outline',
        title: 'Audit',
        to: '/application/audit',
        permissions: ['get-audit'],
      },
      {
        icon: 'mdi-home-group',
        title: 'Offices',
        to: '/application/location-list',
        permissions: ['create-location'],
        separator: true,
      },
      {
        icon: 'mdi-account-supervisor-circle',
        title: 'Officers',
        to: '/application/user-list',
        permissions: ['edit-user', 'get-all-users'],
      },
      {
        icon: 'mdi-package-variant-closed',
        title: 'Packages & Services',
        to: '/application/packages',
        permissions: [
          'get-package',
          'get-all-packages',
          'get-service',
          'get-all-services',
          'get-all-task-types',
        ],
      },
      // {
      //   title: 'Create',
      //   icon: 'mdi-plus',
      //   to: '/application/create-menu',
      //   permissions: [],
      // },
      // {
      //   title: 'Manage',
      //   icon: 'mdi-playlist-edit',
      //   to: '/application/manage-menu',
      //   permissions: ['edit-user', 'get-all-users'],
      // },
      // {
      //   title: 'Logout',
      //   icon: 'mdi-logout-variant',
      //   to: '/logout',
      //   permissions: [],
      // },
    ],
  }),

  computed: {
    ComputedButtons() {
      const ExistingPermissions = this.$store.state.User.Permissions;
      const FilteredButtons = this.items.filter((button) => {
        const RequiredPermissions = button.permissions;
        return RequiredPermissions.every((perm) => ExistingPermissions.includes(perm));
      });
      return FilteredButtons;
    },
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get() {
        return this.$store.state.Theme.drawer;
      },
      set(val) {
        this.$store.commit('Theme/SET_DRAWER', val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      };
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px

[href="/application/new-lead"]
  border-radius: 8px !important
  background-color: mediumseagreen
</style>
